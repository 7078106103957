import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CategoryContainer, 
  Layout, NewProgressContent, ProgressBar, MainProgressBar, PointContent, ImageTag, BronzeImg, SilverImg, GoldImg, FirstClass, SecondClass, ThirdClass, FourClass, Bronze, Silver, Gold, Finished, FlagImg, NewProgressCardV2
} from './newRewardDataStyles';
import { ImageUrl } from '../../utils/constants';
import { commaSeperator} from '../../utils/methods';
import { Waiting } from '../../components';
import { getCompanyRewardTierAPI } from '../../redux/actions';
import { withTranslation } from 'react-i18next';
import NewRewardCatagory from './NewRewardCatagory';
import _ from 'lodash';
import { NewProgressPointsV2, ViewPointsDetails } from './styles';
import moment from 'moment';
import { getHistoryRewardPoints, getUserRewardHistoryDetails } from '../../redux/actions/wellnessDashboardActions';
import LazyImage from '../../components/common/LazyImage/LazyImage';

class RewardDetailsNew extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      firstIndex: 0,
      secondIndex: 0,
      viewDetail: false,
      year: moment().format('YYYY'),
      years: [],
      dropDown: -1,
      rewardData:[]
    }
  }

  componentDidMount() {
    this.setState({
      firstIndex: 10,
      secondIndex: 5,
    });
    const { /*getCompanyRewardTierAPI, companyId,*/ getHistoryRewardPoints, getUserRewardHistoryDetails, userId, created_at } = this.props;
    // getCompanyRewardTierAPI(companyId);
    const currentYear = new Date().getFullYear() - 1;
    let yearArray = [];
    let created = created_at?new Date(created_at.created_at).getFullYear():new Date("2016").getFullYear()
    for (let i = currentYear; i >= created; i--) {
      yearArray.push(i);
      getHistoryRewardPoints(userId, i)
      getUserRewardHistoryDetails(userId, i)
    }
    this.setState({
      years: yearArray,
      rewardData:this.props.rewardData
    })
  }

  handleDetails = (index) => {
    if(this.state.dropDown === index){
      this.setState({ dropDown: -1})
    }else{
      this.setState({ dropDown:index})
    }
  }

  categoryArray = (index) => {
    const { rewardData, userRewardHistoryDetails } = this.props;
    let sortedData = rewardData.sort(
      (y1, y2) => (y1.year < y2.year) ? 1 : (y1.year > y2.year) ? -1 : 0);
    let sorteduserRewardHistoryDetails = userRewardHistoryDetails.sort(
      (y1, y2) => (y1.year < y2.year) ? 1 : (y1.year > y2.year) ? -1 : 0);
    return [
      {
        src: 'Fitness_points_bg',
        title: 'Fitness Points',
        availalePoint: !_.isUndefined(sortedData[index].data)?sortedData[index]?.data?.max_fitness:0,
        earnPoint: !_.isUndefined(sortedData[index].data)?sortedData[index]?.data?.fitness_point:0,
        percentage: !_.isUndefined(sortedData[index].data)?sortedData[index]?.data?.fitness_percentage:0,
        maxAvailableFitnessPoint: !_.isUndefined(sortedData[index]?.data) && !_.isUndefined(sortedData[index]?.data?.max_fitness_points) ? sortedData[index]?.data && sortedData[index]?.data?.max_fitness_points: 0,
        details: !_.isUndefined(sorteduserRewardHistoryDetails[index]?.data) ? sorteduserRewardHistoryDetails[index]?.data?.fitness:[],
        bonus: !_.isUndefined(sorteduserRewardHistoryDetails[index]?.data) ? sorteduserRewardHistoryDetails[index]?.data?.bonus_fitness:[],
      },
      {
        src: 'Social_Points_bg',
        title: 'Social Points',
        availalePoint: !_.isUndefined(sortedData[index]?.data)?sortedData[index]?.data?.max_social:0,
        earnPoint: !_.isUndefined(sortedData[index]?.data)?sortedData[index]?.data?.social_point:0,
        percentage: !_.isUndefined(sortedData[index]?.data)?sortedData[index]?.data?.social_percentage:0,
        details: !_.isUndefined(sorteduserRewardHistoryDetails[index]?.data) ?sorteduserRewardHistoryDetails[index]?.data?.social:[],
        bonus: !_.isUndefined(sorteduserRewardHistoryDetails[index]?.data) ?sorteduserRewardHistoryDetails[index]?.data?.bonus_social:[],
      },
      {
        src: 'healthAssessment_points_bg',
        title: 'Health Assessment Points',
        availalePoint: !_.isUndefined(sortedData[index]?.data)?sortedData[index]?.data?.max_wellness:[],
        earnPoint: !_.isUndefined(sortedData[index]?.data)?sortedData[index]?.data?.wellness_point:0,
        percentage: !_.isUndefined(sortedData[index]?.data)?sortedData[index]?.data?.wellness_percentage:0,
        details: !_.isUndefined(sorteduserRewardHistoryDetails[index]?.data) ?sorteduserRewardHistoryDetails[index]?.data?.wellness:[],
        bonus: !_.isUndefined(sorteduserRewardHistoryDetails[index]?.data) ?sorteduserRewardHistoryDetails[index]?.data?.bonus_wellness:[],
      },
      {
        src: 'Wellness_points_bg',
        title: 'Wellness Points',
        availalePoint: !_.isUndefined(sortedData[index]?.data)?sortedData[index]?.data?.max_education:0,
        earnPoint: !_.isUndefined(sortedData[index]?.data)?sortedData[index]?.data?.education_point:0,
        percentage: !_.isUndefined(sortedData[index]?.data)?sortedData[index]?.data?.education_percentage:0,
        details: !_.isUndefined(sorteduserRewardHistoryDetails[index]) ?sorteduserRewardHistoryDetails[index]?.data?.education:[],
        bonus: !_.isUndefined(sorteduserRewardHistoryDetails[index]?.data) ?sorteduserRewardHistoryDetails[index]?.data?.bonus_education:[],
      }
    ]
  }

  render() {
    const { rewardData, t, companyRewardsBarValues } = this.props;
    if (!rewardData || _.isUndefined(companyRewardsBarValues)) {
      return <Waiting />
    }
    const bronzePoints = !_.isUndefined(companyRewardsBarValues.bronze) ? companyRewardsBarValues.bronze : 0;
    const silverPoints = !_.isUndefined(companyRewardsBarValues.silver) ? companyRewardsBarValues.silver : 0;
    const goldPoints = !_.isUndefined(companyRewardsBarValues.gold) ? companyRewardsBarValues.gold : 0;
    const bronze = bronzePoints / 10000 * 100;
    const silver = silverPoints / 10000 * 100;
    const gold = goldPoints / 10000 * 100;
    let sortedData = rewardData.sort(
      (y1, y2) => (y1.year < y2.year) ? 1 : (y1.year > y2.year) ? -1 : 0);

    let arr = []
    if(sortedData && sortedData.length) {
      arr.push(sortedData[0])
    }
    if(sortedData && sortedData.length) {
      sortedData.map((n)=>{
        let d = false
        if(arr && arr.length) {
          arr.map((i)=>{
            if(n && i) {
              if(n["year"] == i["year"]) {
                d = true
              }
            }
          })
          if(!d) {
            arr.push(n)
          }
        }
      })
    }
    sortedData = arr

    return (
      <div>
        {sortedData && sortedData.length>0?sortedData.length && sortedData.map((dataRewards, index) => (
          <Layout key={index}>
            <NewProgressCardV2 >
              <NewProgressContent>
                <div className='main_content'>
                  {dataRewards.data.total >= 0 &&
                    <div className="newImageData">
                      <ImageTag>
                        <LazyImage src={dataRewards.data.total > goldPoints ? `${ImageUrl}/images/NewRewardSection/gold_bg_img.png` : dataRewards.data.total > silverPoints ? `${ImageUrl}/images/NewRewardSection/slivers_bg_img.png` : dataRewards.data.total > bronzePoints ? `${ImageUrl}/images/NewRewardSection/bronze_bg_img.png` : dataRewards.data.total === 0 ? `${ImageUrl}/images/NewRewardSection/no_BadgeImg.png` : `${ImageUrl}/images/NewRewardSection/no_BadgeImg.png`} />
                      </ImageTag>
                    </div>
                  }
                  <div className='main_point_class'>
                    <div className='point_class'>
                      <div className='total_value'>{t("Total Points")}</div>
                      <div className='total_point_value' style={{marginBottom:'10px'}}>{commaSeperator(dataRewards.data.total)}</div>
                      <div className='border_line' />
                      <div className='left_point' style={{marginTop:'15px'}}><span className='earn_point'>{'Year '+(index + 1)} - </span>{dataRewards.year}</div>
                    </div>
                  </div>
                </div>
              </NewProgressContent>
              <NewProgressPointsV2>
                <MainProgressBar>
                  <ProgressBar width={sortedData[index].data.total <= 10000 ? sortedData[index].data.total * 100 / 10000 : 100} color={sortedData[index].data.total > silverPoints ? "#DCC34C" : sortedData[index].data.total > bronzePoints ? "#B9C1C6" : "#A57576"}>
                    <div className="progrss" />
                    <BronzeImg width={sortedData[index].data.total > bronzePoints ? '100%' : '100%'} imageWidth={(sortedData[index].data.total > bronzePoints + 1) || (sortedData[index].data.total === bronzePoints) ? '1' : ''} left={sortedData[index].data.total < bronzePoints ? `calc(${bronze}% - 38px)` : `calc(${bronze}% - 60px)`} top={(sortedData[index].data.total === 0 && '-27px' ) || (sortedData[index].data.total < bronzePoints ? '-65px' : '-77px')}>
                      <Bronze paddingLeft={sortedData[index].data.total < bronzePoints ? '-5px' : '5px'} paddingBottom={sortedData[index].data.total < bronzePoints ? '10px' : '6px'}>{t("Bronze")}</Bronze>
                      <LazyImage src={`${ImageUrl}/images/Rewards/bronze.png`} />
                      <FirstClass marginTop={sortedData[index].data.total < bronzePoints ? '10px' : '5px'} paddingLeft={sortedData[index].data.total < bronzePoints ? 'none' : '2px'} marginLeft={sortedData[index].data.total < bronzePoints ? '-5px' : 'none'}>{commaSeperator(bronzePoints)}{("pts")}</FirstClass>
                    </BronzeImg>
                    <SilverImg width={sortedData[index].data.total > silverPoints ? '100%' : '100%'} imageWidth={(sortedData[index].data.total > silverPoints + 1) || (sortedData[index].data.total === silverPoints) ? '1' : ''} left={sortedData[index].data.total < silverPoints ? `calc(${silver}% - 35px)` : `calc(${silver}% - 60px)`} top={(sortedData[index].data.total === 0 && '-152px') || (sortedData[index].data.total < silverPoints ? '-190px' : '-201px')}>
                      <Silver paddingLeft={sortedData[index].data.total < silverPoints ? '2px' : '10px'} paddingBottom={sortedData[index].data.total < silverPoints ? '10px' : '6px'}>{t("Silver")}</Silver>
                      <LazyImage src={`${ImageUrl}/images/Rewards/silver.png`} />
                      <SecondClass marginTop={sortedData[index].data.total < silverPoints ? '12px' : '8px'} paddingLeft={sortedData[index].data.total < silverPoints ? 'none' : '2px'} marginLeft={sortedData[index].data.total < silverPoints ? '-5px' : 'none'}>{commaSeperator(silverPoints)}{("pts")}</SecondClass>
                    </SilverImg>
                    <GoldImg width={sortedData[index].data.total > goldPoints ? '100%' : '100%'} imageWidth={(sortedData[index].data.total > goldPoints + 1) || (sortedData[index].data.total === goldPoints) ? '1' : ''} left={sortedData[index].data.total < goldPoints ? `calc(${gold}% - 35px)` : `calc(${gold}% - 60px)`} top={(sortedData[index].data.total === 0 && '-275px' ) || (sortedData[index].data.total < goldPoints ? '-314px' : '-324px')}>
                      <Gold paddingLeft={sortedData[index].data.total < goldPoints ? '2px' : '10px'} paddingBottom={sortedData[index].data.total < goldPoints ? '10px' : '6px'}>{t("Gold")}</Gold>
                      <LazyImage src={`${ImageUrl}/images/Rewards/gold.png`} />
                      <ThirdClass marginTop={sortedData[index].data.total < goldPoints ? '10px' : '5px'} paddingLeft={sortedData[index].data.total < goldPoints ? 'none' : '2px'} marginLeft={sortedData[index].data.total < goldPoints ? '-5px' : 'none'}>{commaSeperator(goldPoints)}{("pts")}</ThirdClass>
                    </GoldImg>
                    <FlagImg top={sortedData[index].data.total === 0 ? '-400px' : '-445px'} left={'calc(100% - 35px)'}>
                      <Finished>{t("Finish")}</Finished>
                      <LazyImage src={`${ImageUrl}/images/NewRewardSection/finish-flag.png`} />
                      <FourClass>10,000{("pts")}</FourClass>
                    </FlagImg>
                  </ProgressBar>
                </MainProgressBar>
                <ViewPointsDetails onClick={() => this.handleDetails(index)}>
                  <div>{t("View Point Details")}
                    {this.state.dropDown === index ? <img src='/public/images/viewUpArrow.png'></img> : <img src='/public/images/viewDownArrow.png'></img>}
                  </div>
                </ViewPointsDetails>
                <PointContent />
              </NewProgressPointsV2>
            </NewProgressCardV2>
            { this.state.dropDown === index && <CategoryContainer>
              {this.categoryArray(sortedData.indexOf(dataRewards)).map((category, index) => (
                <NewRewardCatagory category={category} key={index} history={this.props.history}  getIndex={index} />
              ))}
            </CategoryContainer>}
          </Layout> 
        )):<h1>{t("No Reward History")}</h1>}
      </div>
    );
  }
}

RewardDetailsNew.propTypes = {
  monthlyRewardPoint: PropTypes.number,
  fitnessDetails: PropTypes.array,
  socialDetails: PropTypes.array,
  wellnessDetails: PropTypes.array,
  educationDetails: PropTypes.array,
  bonusWellness: PropTypes.array,
  bonusSocial: PropTypes.array,
  getUserRewardDetails: PropTypes.func,
  history: PropTypes.object,
  bonusFitness: PropTypes.array,
  bonusEducation: PropTypes.array,
  t: PropTypes.func,
  getCompanyRewardTierAPI: PropTypes.func,
  companyRewardsBarValues: PropTypes.array,
  companyId: PropTypes.number,
  getHistoryRewardPoints: PropTypes.func,
  getUserRewardHistoryDetails: PropTypes.func,
  userId: PropTypes.number,
  rewardData: PropTypes.array,
  userRewardHistoryDetails: PropTypes.array,
  created_at: PropTypes.number
};

const mapStateToProps = (state) => ({
  reward: state.wellnessDashboard.reward,
  monthlyRewardPoint: state.wellnessDashboard.monthlyRewardPoint,
  fitnessDetails: state.wellnessDashboard.fitnessDetails,
  socialDetails: state.wellnessDashboard.socialDetails,
  wellnessDetails: state.wellnessDashboard.wellnessDetails,
  educationDetails: state.wellnessDashboard.educationDetails,
  bonusWellness: state.wellnessDashboard.bonusWellness,
  bonusSocial: state.wellnessDashboard.bonusSocial,
  bonusFitness: state.wellnessDashboard.bonusFitness,
  bonusEducation: state.wellnessDashboard.bonusEducation,
  companyRewardsBarValues: state.wellnessDashboard.companyRewardsBarValues,
  userId: state.profileData.userId,
  rewardData: state.wellnessDashboard.rewardData,
  created_at: state.profileData.user,
  userRewardHistoryDetails: state.wellnessDashboard.userRewardHistoryDetails
});

const mapDispathcToProps = (dispatch) => ({
  getUserRewardHistoryDetails: (userId, year) => dispatch(getUserRewardHistoryDetails(userId, year)),
  getCompanyRewardTierAPI: (companyId) => dispatch(getCompanyRewardTierAPI(companyId)),
  getHistoryRewardPoints: (userId, year) => dispatch(getHistoryRewardPoints(userId, year))
})

export default connect(mapStateToProps, mapDispathcToProps)(withTranslation()(RewardDetailsNew));